<script>
import Stat from "@/components/widgets/stat";
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      Team: "Equipe",
      "Pending Confirmations": "Confirmações Pendentes",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tabuleiros",
      Position: "Posição",
      Status: "Status",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Team: "Equipe",
      "Pending Confirmations": "Confirmaciones Pendientes",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tableros",
      Position: "Posição",
      Status: "Status",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      page: 1,
      pages: 0,
      modal: false,
      board: null,
      titleModal:null,
      folder:null,
      statData: null,
      type:null,
      estacao:null,
      level:null,
      username:null,
      position:null,
      email:null,
      cellphone:null,
     
      estacoes: [
      { key: '', value: '' },
      { key: '10', value: 'Estação 10'  , positions:[{ key: '', value: '' }, { key: 0 , value: "master" }, { key: 1 , value: "coordinator" }, { key: 2 , value: "indicator" }, { key: 3 , value: "donator" }], types:["","connect","start"] },
      { key: '100', value: 'Estação 100' , positions:[{ key: '', value: '' }, { key: 0 , value: "master" }, { key: 1 , value: "coordinator" }, { key: 2 , value: "indicator" }, { key: 3 , value: "donator" }], types:["","black","bronze","diamond","gold","silver"]  },
      { key: '1000', value: 'Estação 1000' , positions:[{ key: '', value: '' }, { key: 0 , value: "master" }, { key: 1 , value: "coordinator" }, { key: 2 , value: "indicator" }, { key: 3 , value: "donator" }], types:["","basic","plus","premium"]   },
      { key: 'perfect', value: 'Estação Perfect' , positions:[{ key: '', value: '' }, { key: 0 , value: "master" }, { key: 1 , value: "indicator" }, { key: 2 , value: "donator" }], types:["","speed"]   },
      ],
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getTeam() {
      this.table.loading = true;
      api
        .post("network/team?page=" + this.page, {
          username: this.username,
          level: this.level,
          estacao: this.estacao ? this.estacao.key: null,
          type:this.type,
          position:this.position,
          email:this.email,
          cellphone:this.cellphone
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.table.empty = response.data.total == 0 ? true : false;
            this.table.body = response.data.list;
            this.table.errored = null;
            this.pages = response.data.pages;
            this.statData = [
              {
                title: "Total",
                value: response.data.total.toString(),
              },
            ];
          }else{
            this.table.empty = true;
            this.table.body = null;
            this.table.errored = null;
            this.pages = 0;
            this.statData = [
              {
                title: "Total",
                value: "0",
              },
            ];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
    showBoard(board,folder,titleModal) {
      this.titleModal=titleModal;
      this.folder=folder;
      this.board = board;
      this.modal = true;
    },
    isDonatorActive(boards) {
      let donator = false;
      boards.forEach(element => {
        if (element.positions > 0)
          donator = true;
      });
      return donator;
    },
    cleanForm(){
      this.type=null;
      this.estacao=null;
      this.level=null;
      this.username=null;
      this.position=null;
      this.email=null;
      this.cellphone=null;
    }
  },
  mounted() {
    //this.getTeam();
    this.table.loading = false;
    this.table.empty = true;
  },
  watch: {
    page: function () {
      window.scrollTo(0, 0);
      this.getTeam();
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Team") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit.prevent="getTeam">
              <div class="row">
                <div class="col-md-4 text-center-sm">
                  <b-form-group :label="t('Username')" label-for="username">
                    <b-form-input id="username" v-model="username" type="text" autocomplete="off"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 text-center-sm">
                  <b-form-group :label="t('Cellphone')" label-for="cellphone">
                    <b-form-input id="cellphone" v-model="cellphone" type="text" autocomplete="off"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-4 text-center-sm">
                  <b-form-group :label="t('E-mail')" label-for="email">
                    <b-form-input id="email" v-model="email" type="text" autocomplete="off"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-2 text-center-sm">
                  <b-form-group :label="t('Level')" label-for="level">
                    <select v-model="level" class="custom-select mb-1">
                      <option >
                        
                      </option>
                      <option v-for="(option) in 100" :key="option" :value="option">
                        {{ option }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-2 text-center-sm">
                  <b-form-group :label="t('Estação')" label-for="estacao">
                    <select v-model="estacao" class="custom-select mb-1">
                      <option v-for="(option) in estacoes" :key="option.key" :value="option">
                        {{ option.value.toUpperCase() }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-2 text-center-sm"  v-if="estacao && estacao.types" >
                  <b-form-group :label="t('Type')" label-for="type">
                    <select v-model="type" class="custom-select mb-1">
                      <option v-for="(option) in estacao.types" :key="option" :value="option">
                        {{ option.toUpperCase() }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-2 text-center-sm"  v-if="estacao && estacao.positions" >
                  <b-form-group :label="t('Position')" label-for="position">
                    <select v-model="position" class="custom-select mb-1">
                      <option v-for="(option) in estacao.positions" :key="option.key" :value="option.key">
                        {{ option.value.toUpperCase() }}
                      </option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-2 text-center-sm" style="margin-top: 28px;">
                  <b-button :disabled="this.table.loading == true" type="submit" variant="default">
                    {{ t('Filter') }}
                    <b-spinner v-if="this.table.loading" small class="ml-2 align-middle" variant="white"
                      role="status"></b-spinner>
                  </b-button>
                  <b-button :disabled="this.table.loading == true" type="button" 
                  style="margin-left: 5px;"
                  v-on:click="cleanForm()" variant="danger">
                    {{ t('Clean') }}
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored" class="text-center">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty" class="text-center">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Contatos</th>
                    <th>Nível</th>
                    <th>Estações</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td class="notranslate">
                      {{ td.username }}
                    </td>
                    <td>
                      {{ td.cellphone }}
                      <a target="_blank" :href="'https://api.whatsapp.com/send?phone=' +
                        td.cellphone
                          .replace('+', '')
                          .replace('.', '')
                          .replace(' ', '')
                          .replace('-', '')
                        ">
                        <i class="bx bxl-whatsapp font-size-24 align-middle text-success"></i>
                      </a>
                    </td>
                    <th>{{ td.level }}</th>
                    <td class="p-0">
                      <div class="d-flex">
                        <div v-if="isDonatorActive(td.boards10)" class="board-position">
                          <img v-on:click="showBoard(td.boards10,'station10','Tabuleiro Estação 10')" v-b-tooltip.hover title="Estação 10" style="width: 45px; cursor:pointer"
                            :src="require('@/assets/images/boards/EST-10.png')" />

                        </div>
                        <div v-if="isDonatorActive(td.boards100)" class="board-position">
                          <img v-on:click="showBoard(td.boards100,'station100','Tabuleiro Estação 100')" v-b-tooltip.hover  title="Estação 100" style="width: 45px; cursor:pointer"
                            :src="require('@/assets/images/boards/EST-100.png')" />
                        </div>
                        <div v-if="isDonatorActive(td.boards1000)" class="board-position">
                          <img v-on:click="showBoard(td.boards1000,'station1000','Tabuleiro Estação 1000')" v-b-tooltip.hover  title="Estação 1000" style="width: 45px; cursor:pointer"
                            :src="require('@/assets/images/boards/EST-1000.png')" />
                        </div>
                        <div v-if="isDonatorActive(td.boardsperfect)" class="board-position">
                          <img v-on:click="showBoard(td.boardsperfect,'stationperfect','Tabuleiro Estação Perfect')" v-b-tooltip.hover  title="Estação Perfect" style="width: 45px; cursor:pointer"
                            :src="require('@/assets/images/boards/EST-SPEED.png')" />
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <img class="img-responsive" v-if="td.country"
                        :src="'https://flagcdn.com/24x18/' + td.country.toLowerCase() + '.png'">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginate v-if="!table.errored && !table.empty" v-model="page" :page-count="pages" :page-range="3"
              :margin-pages="2" prev-text="<i class='mdi mdi-chevron-left'></i>"
              next-text="<i class='mdi mdi-chevron-right'></i>"
              :container-class="'pagination pagination-rounded justify-content-center mt-4'" :page-class="'page-item'"
              :page-link-class="'page-link'" :prev-link-class="'page-link'" :next-link-class="'page-link'"
              :break-view-link-class="'page-link'">
            </paginate>
          </div>
        </div>
      </div>
    </div>


    <b-modal    v-model="modal" :title="titleModal" centered size="custom-modal">
      <div class="text-center">
        <div class="d-flex">
          <div v-for="(boa, i) in board" :key="i" :class="[boa.positions == 0 ? 'disabled' : '']"
            class="board-sub-position">
            <img v-b-tooltip.hover style=""
              :class="[boa.positions == 0 ? 'disabled pin-' + boa.level : 'pin-' + boa.level]" :src="require('@/assets/images/boards/' +
                folder+'/'+
                boa.id +
                '-' +
                boa.level +
                '.png')
                " />
            <span class="board-sub-name">{{ boa.level.toUpperCase() }}</span>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style>

.modal-custom-modal .modal-content {
  min-width: 560px;
}
</style>
<style scoped>

.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}

.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.board-position {
  position: relative;
  margin: 0 5px;
  width: 55px;
  text-align: center;
}


.board-sub-position {
  position: relative;
  margin: 0 7.5px;
  width: 85px;
  text-align: center;
}

.board-position img {
  width: 100%;
}

.board-sub-position img {
  width: 85px;
}
.board-total {
  position: absolute;
  top: 0px;
  right: 0px;
}

.board-name {
  position: absolute;
  top: 16px;
  left: 0;
  width: 55px;
  font-size: 8px;
  text-transform: uppercase;
  color: #fff;
  background: #000;
  border-radius: 10px;
  text-align: center;
}


.board-sub-name {
  position: absolute;
  top: 34px;
  left: 0px;
  width: 85px;
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  background: #000;
  border-radius: 10px;
  text-align: center;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
